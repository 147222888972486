import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from "docz";
import StaticPremiumTable from "./StaticPremiumTable";
import { data } from './mock';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "staticpremiumtable"
    }}>{`StaticPremiumTable`}</h1>
    <h2 {...{
      "id": "attributes"
    }}>{`Attributes`}</h2>
    <Props of={StaticPremiumTable} mdxType="Props" />
    <h2 {...{
      "id": "basic-usage"
    }}>{`Basic Usage`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`const data = {
  headers: ['Suspendisse interdum', 'Ipsum dolor sit amet'],
  rows: [
    ['Suspendisse interdum, arcu sed tincidunt pharetra.', 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.'],
    ['Lorem ipsum dolor sit amet.', 'Suspendisse interdum, arcu sed tincidunt pharetra.'],
    ['Suspendisse interdum, arcu sed tincidunt pharetra.', 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.'],
    ['Lorem ipsum dolor sit amet.', 'Suspendisse interdum, arcu sed tincidunt pharetra.'],
    ['Suspendisse interdum, arcu sed tincidunt pharetra.', 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.'],
    ['Lorem ipsum dolor sit amet.', 'Suspendisse interdum, arcu sed tincidunt pharetra.'],
    ['Suspendisse interdum, arcu sed tincidunt pharetra.', 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.'],
    ['Lorem ipsum dolor sit amet.', 'Suspendisse interdum, arcu sed tincidunt pharetra.'],
    ['Suspendisse interdum, arcu sed tincidunt pharetra.', 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.'],
    ['Lorem ipsum dolor sit amet.', 'Suspendisse interdum, arcu sed tincidunt pharetra.'],
    ['Suspendisse interdum, arcu sed tincidunt pharetra.', 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.'],
    ['Lorem ipsum dolor sit amet.', 'Suspendisse interdum, arcu sed tincidunt pharetra.'],
    ['Suspendisse interdum, arcu sed tincidunt pharetra.', 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.'],
    ['Lorem ipsum dolor sit amet.', 'Suspendisse interdum, arcu sed tincidunt pharetra.'],
  ]
};
`}</code></pre>
    <h3 {...{
      "id": "table-with-vertical-line"
    }}>{`Table with vertical line`}</h3>
    <Playground __position={1} __code={'<StaticPremiumTable data={data} displayCount={5} verticalLine={true} />'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      StaticPremiumTable,
      data,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <StaticPremiumTable data={data} displayCount={5} verticalLine={true} mdxType="StaticPremiumTable" />
    </Playground>
    <h3 {...{
      "id": "table-without-vertical-line-and-changed-the-header-colors"
    }}>{`Table without vertical line and changed the header colors`}</h3>
    <Playground __position={2} __code={'<StaticPremiumTable\n  data={data}\n  displayCount={5}\n  leftHeaderColor={\'#555\'}\n  topHeaderColor={\'#BBB\'}\n/>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      StaticPremiumTable,
      data,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <StaticPremiumTable data={data} displayCount={5} leftHeaderColor={'#555'} topHeaderColor={'#BBB'} mdxType="StaticPremiumTable" />
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      